.lia-solved-badge {
  padding-left: 0;
  padding-right: 10px;
  font-size: var(--lia-bs-font-size-sm);
  font-weight: var(--lia-bs-font-weight-bold);
  color: var(--lia-forum-solved-color);
  background-color: hsla(
    var(--lia-forum-solved-color-h),
    var(--lia-forum-solved-color-s),
    var(--lia-forum-solved-color-l),
    8%
  );
  height: 38px;
  align-items: center;

  .lia-solved-icon {
    margin: 0 8px 0 8px;
    width: 12px;
  }
}
